<template>
	<v-card class="my-2 mx-auto" max-width="1200" ripple :to="{ name: 'Event', params: { id: event.id } }" rounded="xl">
		<v-card-title class="mb-2 text-uppercase font-weight-bold" style="word-break: normal">{{ event.title }}</v-card-title>
		<v-card-subtitle class="pb-1" style="word-break: normal">
			<span style="color: orange">{{ event.type.name }}</span> <v-icon>mdi-map-marker</v-icon>
			{{ event.info.location }}
		</v-card-subtitle>
		<v-card-text>
			<p>
				<span>
					<v-icon>mdi-account-group</v-icon>
					{{ event.attendances.numTotal > 0 ? event.attendances.numTotal : '--' }} / {{ event.attendances.maxCapacity }}
				</span>
			</p>
			<div>
				<span class="text-body-1" style="color: #FF6F00"><v-icon>mdi-calendar</v-icon> {{ humanTime(event.info.timestamp.start) }}</span>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'MyEventCard',
	props: {
		event: {
			type: Object,
			required: true
		}
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
		}
	}
}
</script>
